export enum SPECS {
  ENABLE_LOST_BUSINESS_EMAIL = 'specs.stores.EnableLostBusinessEmail',
  SUBSCRIPTION_PLANS = 'specs.stores.ProductSubscriptionsSF',
  CASHIER_EXPRESS_IN_PRODUCT_PAGE = 'specs.stores.CashierExpressInProductPage',
  SUPPORT_ALT_TEXT = 'specs.stores.SupportAltTextInImages',
  NEW_ZOOM_IN_CLASSIC_LAYOUT = 'specs.stores.ClassicProductPageZoomAB',
  SUPPORT_ORIGIN = 'specs.stores.CheckoutOrigin',
  SELLING_IN_UNITS_SF = 'specs.stores.SellingInUnitsSF',
  USE_LIGHTBOXES = 'specs.stores.UseLightboxes',
}
